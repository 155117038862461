const LegacyDirectReposDownloads = () => {
  return (
    <div className="pt-5 px-3 ms-2 bg-light pb-4">
      <div className="px-3 container">
        <h2 id="direct-repos-downloads">Direct Repo Downloads</h2>
        <p>
          Browse RPM files and Download files.
        </p>
        <div className="card shadow py-5 ps-5 mb-5 bg-body rounded">
          <div className="container">
            <h4 className="ps-0">Yum</h4>

            <div className="row">
              <div className="col-sm-5 col-md-6 fake-list-wrapper">
                <p className="fake-list-title mb-0 mt-1">
                  Browse <span className="fw-bold">RPM</span> files by platform
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/rhel-7-x86_64/repoview"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    RHEL/CentOS/Scientific Linux 7.x, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/rhel-7-ppc64le/repoview"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    IBM RHEL/CentOS PPCLE 7.x, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/rhel-8-x86_64/repoview"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    RHEL/Rocky/Alma Linux 8.x, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/rhel-8-ppc64le/repoview"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    IBM RHEL PPCLE 8.x, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/rhel-9-x86_64/repoview/"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    RHEL/Rocky/Alma Linux 9.x, 64bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/rhel-9-ppc64le/repoview/"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    IBM RHEL PPCLE 9.x, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/SRPMS/repoview"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Source RPMs
                  </a>
                </p>
                
              </div>
              <div className="col-sm-5 offset-sm-2 col-md-6 offset-md-0 fake-list-wrapper ">
                <p className="fake-list-title mb-0 mt-1">
                  Download <span className="fw-bold">tarballs</span> containing all RPMs within a Yum repository
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/edb_redhat_rhel-7-x86_64.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - RHEL/CentOS/Scientific Linux 7.x, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/edb_redhat_rhel-7-ppc64le.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - IBM RHEL/CentOS PPCLE 7.x, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/edb_redhat_rhel-8-x86_64.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - RHEL/Rocky/Alma Linux 8.x, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/edb_redhat_rhel-8-ppc64le.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - IBM RHEL PPCLE 8.x, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/edb_redhat_rhel-9-x86_64.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - RHEL/Rocky/Alma Linux 9.x, 64bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/edb_redhat_rhel-9-ppc64le.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - IBM RHEL PPCLE 9.x, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://yum.enterprisedb.com/edb/redhat/edb_redhat_SRPMS.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - Source RPMs
                  </a>
                </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow py-5 ps-5 mb-5 bg-body rounded">
          <div className="container content-wrapper">
            <h4 className="ps-0">Apt</h4>

            <div className="row">
              <div className="col-sm-5 col-md-6 fake-list-wrapper">
                <p className="fake-list-title mb-0 mt-1">
                  Browse <span className="fw-bold">Debian</span> package files by platform
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                      href="https://apt.enterprisedb.com/buster-edb/pool/main/"
                      target={'_blank'}
                      className="link-teal text-decoration-none"
                  >
                    Debian 10 (Buster), 64 bit 
                  </a>
                </p>
                
                <p className="fake-list-item mb-0">
                  <a 
                      href="https://apt.enterprisedb.com/bullseye-edb/pool/main/"
                      target={'_blank'}
                      className="link-teal text-decoration-none"
                  >
                    Debian 11 (Bullseye), 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://apt.enterprisedb.com/bionic-edb/pool/main"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Ubuntu 18.04 LTS (Bionic), 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://apt.enterprisedb.com/focal-edb/pool/main"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Ubuntu 20.04 LTS (Focal), 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://apt.enterprisedb.com/jammy-edb/pool/main"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Ubuntu 22.04 LTS (Jammy), 64 bit
                  </a>
                </p>
              </div>
              <div className="col-sm-5 offset-sm-2 col-md-6 offset-md-0 fake-list-wrapper">
                <p className="fake-list-title mb-0 mt-1">
                  Download <span className="fw-bold">tarballs</span> containing all Debian packages within a Apt repository
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://apt.enterprisedb.com/edb_buster-edb.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - Debian 10 (Buster), amd64
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://apt.enterprisedb.com/edb_bullseye-edb.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - Debian 11 (Bullseye), amd64
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://apt.enterprisedb.com/edb_bionic-edb.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - Ubuntu 18.04 (Bionic), amd64 
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://apt.enterprisedb.com/edb_focal-edb.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - Ubuntu 20.04 (Focal), amd64 
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://apt.enterprisedb.com/edb_jammy-edb.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Tarball - Ubuntu 22.04 (Jammy), amd64
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow py-5 ps-5 mb-5 bg-body rounded">
          <div className="container">
            <h4 className="ps-0">Zypper</h4>
            <div className="row">
              <div className="col-sm-5 col-md-6 fake-list-wrapper">
                <p className="fake-list-title mb-0 mt-1">
                  Browse <span className="fw-bold">RPM</span> files by platform
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://zypp.enterprisedb.com/edb/suse/suse-12-x86_64/repoview"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    SUSE 12, 64 bit 
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://zypp.enterprisedb.com/edb/suse/suse-12-ppc64le/repoview/"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    SUSE 12 PPCLE, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://zypp.enterprisedb.com/edb/suse/suse-15-x86_64/repoview/"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    SUSE 15, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://zypp.enterprisedb.com/edb/suse/suse-15-ppc64le/repoview/"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    SUSE 15 PPCLE, 64 bit
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://zypp.enterprisedb.com/edb/suse/SRPMS/repoview"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                    Source RPMs
                  </a>
                </p>
              </div>
              <div className="col-sm-5 col-md-6 fake-list-wrapper">
                <p className="fake-list-title mb-0 mt-1">
                  Download <span className="fw-bold">tarballs</span> containing all RPMs within a Zypper repository
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://zypp.enterprisedb.com/edb/suse/edb_suse_suse-12-x86_64.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                   Tarball - SUSE 12, 64 bit 
                  </a>
                </p>
                <p className="fake-list-item mb-0">
                  <a 
                    href="https://zypp.enterprisedb.com/edb/suse/edb_suse_suse-12-ppc64le.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                   Tarball - SUSE 12 PPCLE, 64 bit
                  </a>
                </p>
                 <p className="fake-list-item mb-0">
                  <a 
                    href="https://zypp.enterprisedb.com/edb/suse/edb_suse_suse-15-x86_64.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                   Tarball - SUSE 15, 64 bit
                  </a>
                </p>
                 <p className="fake-list-item mb-0">
                  <a 
                    href="https://zypp.enterprisedb.com/edb/suse/edb_suse_suse-15-ppc64le.tar.gz"
                    target={'_blank'}
                    className="link-teal text-decoration-none"
                  >
                   Tarball - SUSE 15 PPCLE, 64 bit
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegacyDirectReposDownloads;