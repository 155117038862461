import { useLayoutEffect } from "react";
import Layout from "../../components/layout/Layout";
import Hero from "../../components/layout/page/Hero";
import ReposListPageLayout from "../../components/layout/page/ReposListPageLayout";
import { reposAccessApi } from "../../client/edbApiClient";
import { useSelector, useDispatch } from "react-redux";
import { reposAccessReceived } from "../../store/reposAccessSlice";
import { ReposAccess } from "../../resources/Types";
import Repos2PackagesUrls from "../../components/repos/Repos2PackagesUrls";
import Repos2KubernetesDocLinks from "../../components/repos/Repos2KubernetesDocLinks";

interface reposAccessState {
  reposAccess: ReposAccess;
}

const Repos2ListPage = () => {
  const reposAccess = useSelector(
    (state: reposAccessState) => state.reposAccess
  );
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    fetchReposAccess();
  }, [dispatch]);

  const fetchReposAccess = async () => {
    return await reposAccessApi().then((res) => {
      dispatch(reposAccessReceived(res));
    });
  };

  let showLegacy = false;

  interface Legacy {
    repo?: {
      expiration: string | null;
      status: boolean;
    };
  }

  let legacy: Legacy | any;

  // Get reposAccess data.
  if (
    typeof reposAccess === "object" &&
    "access" in reposAccess &&
    Object.keys(reposAccess.access).length > 0
  ) {
    legacy = reposAccess.access.legacy;
    if (
      Object.keys(legacy).length > 0 &&
      "repo" in legacy &&
      "status" in legacy.repo
    ) {
      showLegacy = legacy.repo.status;
    }
  }

  let subLink = {
    title: "",
    url: "",
  };
  // Only show link to Repos 1.0 if user has access.
  if (showLegacy) {
    subLink = {
      title: "Switch to Legacy Repos",
      url: "repos/legacy",
    };
  }

  return (
    <Layout>
      <Hero
        title="EDB repositories for Linux"
        class="repos"
        sublink={subLink}
      />
      <ReposListPageLayout page="repos" repoType="cloudsmith" />
      <Repos2PackagesUrls />
      <Repos2KubernetesDocLinks />
    </Layout>
  );
};

export default Repos2ListPage;
