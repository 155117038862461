const config = {
  drupal: {
    baseUrl: process.env.REACT_APP_DRUPAL_BASE_URL,
    client_id: process.env.DRUPAL_CLIENT_ID,
    client_secret: process.env.DRUPAL_CLIENT_SECRET,
    username: process.env.DRUPAL_USERNAME,
    password: process.env.DRUPAL_PASSWORD,
  },
  edbApi: {
    baseUrl: process.env.REACT_APP_EDB_API_URL,
  },
  edbWeb: {
    baseUrl: process.env.REACT_APP_EDB_WWW_URL,
  },
  okta: {
    issuer: process.env.REACT_APP_OKTA_ISSUER_URL,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    pkce: true,
    tokenManager: {
      //storage: 'sessionStorage'
      storage: 'cookie'
    },
    scopes: ['openid', 'profile', 'email']
  },
  productRegistry: {
    basicAuth: process.env.REACT_APP_PRODUCT_REGISTRY_BASIC_AUTH,
  },
  googleTagManager: {
    id: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  }
};

export default config;
