import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import "../../css/which-repo.css";

const WhichRepoModal = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="modal-flex align-items-end justify-content-end">
        <Button 
          variant="link" 
          onClick={handleShow}
          className="text-decoration-none"
        >
          Which repo should I use?
        </Button>
      </div>
      <Modal 
        show={show} 
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="px-6 pb-6">
            <p className="">
              Unless you have been advised by EDB to use Repos 1.0, you should use Repos 2.0. All EDB Software is now available from Repos 2.0 using a package manager and for download as individual packages or tarballs. We will be retiring Repos 1.0 later this year.
            </p>
            <p></p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default WhichRepoModal;