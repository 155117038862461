import React, { useRef, useState, useEffect } from "react";
import WhichRepoModal from "./WhichRepoModal";
import AccessReposBtn from "./AccessReposBtn";
import { oktaUserApi, oktaUserUsername } from "../../client/edbApiClient";
import { Okta } from "../../resources/Types";
import ToolTipOverlay from "../ToolTipOverlay";
import "../../css/repo-downloads.css";

interface Repos1Props {
  username: string;
  requestAccess: boolean;
}

const Repos1Card = ( props: Repos1Props ) => {

  const [reposUserName, setReposUserName] = useState(props.username)

  useEffect(() => {
    const getOktaReposUserName = async () => {
      const result = await oktaUserUsername();
      if (result) {
        const reposUserName = (result.edb_repo_username && result.edb_repo_username) ? result.edb_repo_username : result.login;
        setReposUserName(reposUserName);
      }
    }
    getOktaReposUserName();
  }, []);

  const passwordRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const spinnerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const revealRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const hideRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copyPasswordRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copiedPasswordRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const usernameRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copyUsernameRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copiedUsernameRef = useRef() as React.MutableRefObject<HTMLSpanElement>;

  const repoPasswordReveal = (copy: boolean) => {
    // Show spinner.
    spinnerRef.current.className = spinnerRef.current.className.replace('d-none', '');

    // Get password data attribute.
    const passwordAttr = passwordRef.current.getAttribute('data-password');
    // Type Guard the passwordAttr to pass to fetchUserProfile() function
    // to prevent null error on passing argument to the function.
    const passwordAttrString: string = passwordAttr !== null ? passwordAttr : "";
    if (passwordRef.current.className === "repo-cred-hidden") {
      // Get Repo Password.
      fetchUserProfile(passwordAttrString, copy);
    }
    else {
      if (copy) {
        // Hide spinner.
        spinnerRef.current.className = spinnerRef.current.className + ' d-none';
        // Copy token to clipboard.
        navigator.clipboard.writeText(
          passwordAttrString,
        );
        // Change Copy label to Copied.
        copyPasswordRef.current.className = "d-none";
        copiedPasswordRef.current.className = "show";
        setTimeout(() => {
          copyPasswordRef.current.className = "show";
          copiedPasswordRef.current.className = "d-none";
        }, 3000)
      }
      else {
        // Change className back to hidden.
        passwordRef.current.className = "repo-cred-hidden";
        // Hide password.
        passwordRef.current.innerHTML = "..........";
        // Hide spinner.
        spinnerRef.current.className = spinnerRef.current.className + ' d-none';
        // Change Hide label to Reveal.
        revealRef.current.className = "show mx-3";
        hideRef.current.className = "d-none";
      }
    }
  }

  const fetchUserProfile = async (passwordAttr: string, copy: boolean) => {
    if (passwordAttr === "") {
      // Getting password from api.
      return await oktaUserApi()
      .then((res: Okta[] | unknown) => {
        let password = "";
        if (res !== null && typeof res === "object" && "profile" in res) {
          const okta: Okta = res;
          const profile = okta.profile;
          if (typeof profile !== "undefined" && 'repo_password' in profile && typeof profile.repo_password === "string") {
            password = profile.repo_password
          }
          else {
            password = "Something went wrong";
          }
        }
        if (copy) {
          // Copy password to clipboard.
          navigator.clipboard.writeText(
            password,
          );
          // Change Copy label to Copied.
          copyPasswordRef.current.className = "d-none";
          copiedPasswordRef.current.className = "show mt-1";
          setTimeout(() => {
            copyPasswordRef.current.className = "show mt-1";
            copiedPasswordRef.current.className = "d-none";
          }, 3000)
        }
        else {
          // Change class name.
          passwordRef.current.className = 'repo-cred-shown';
          // Show Password.
          passwordRef.current.innerHTML = password;
          // Change Reveal label to Hide.
          revealRef.current.className = "d-none";
          hideRef.current.className = "show mx-3";
        }
        // Set token data attribute to reveal by data attribute
        // on subsequent clicks to avoid more api calls.
        passwordRef.current.setAttribute('data-password', password);
        // Hide spinner.
        spinnerRef.current.className = spinnerRef.current.className + ' d-none'
      })
    }
    else {
      // Getting password from data attribute.
      if (copy) {
        // Copy token to clipboard.
        navigator.clipboard.writeText(
          passwordAttr,
        );
        // Change Copy label to Copied.
        copyPasswordRef.current.className = "d-none";
        copiedPasswordRef.current.className = "show mt-1";
        setTimeout(() => {
          copyPasswordRef.current.className = "show mt-1";
          copiedPasswordRef.current.className = "d-none";
        }, 3000)
      }
      else {
        // Change class name.
        passwordRef.current.className = 'repo-cred-shown';
        // Show Password.
        passwordRef.current.innerHTML = passwordAttr;
        // Change Reveal label to Hide.
        revealRef.current.className = "d-none";
        hideRef.current.className = "show mx-3";
      }

      // Hide spinner.
      spinnerRef.current.className = spinnerRef.current.className + ' d-none'
    }
  }

  const copyUsername = () => {
    const username = usernameRef.current.innerText
    // Copy username to clipboard.
    navigator.clipboard.writeText(
      username,
    );
    // Change Copy label to Copied.
    copyUsernameRef.current.className = "d-none";
    copiedUsernameRef.current.className = "show";
    setTimeout(() => {
      copyUsernameRef.current.className = "show";
      copiedUsernameRef.current.className = "d-none";
    }, 3000)
  }

  return (
    <div id="repo1-card" className="card p-5 mb-5">
      <h2 className="h4">
        EDB Repos 1.0
        <sup className="fs-6 ms-1 lh-sm">Legacy</sup>
      </h2>
      <div className="align-items-end justify-content-between mobile-container container">
        <div className="card-row mobile-w100 justify-end-mobile">
          <div className="d-flex flex-column justify-space-between card-col-6 align-self-start">
          <p className="first-p hide-mobile">
            Access repository scripts for all EDB Linux-based software.
          </p>
          <p className="hide-mobile">
            <span className="fw-bold">Please note:</span> Use your EDB Repo username and Repo password in the generated scripts.
          </p>
        </div>
        <div className="card-col-6">
          <div className="mb-2 repo-creds repo-legacy">
            <div className="d-flex justify-content-between align-items-start">
              <div className="d-flex flex-grow flex-row">
                <span className="fw-bold me-2">Repo Username:</span>
                <span ref={usernameRef}>{reposUserName}</span>
              </div>
              <div className="d-flex gap-1">
                  <span
                    ref={copyUsernameRef}
                    onClick={copyUsername}
                  >
                    <ToolTipOverlay
                      keyId="username-copy"
                      placement="top"
                      tooltipLabel="Copy Username"
                      tooltipClass="tooltip-token"
                      component="CopyIcon"
                    />
                  </span>
                  <span
                    ref={copiedUsernameRef}
                    className="d-none"
                  >
                    <ToolTipOverlay
                      keyId="username-copied"
                      placement="top"
                      tooltipLabel="Copied Username"
                      tooltipClass="tooltip-token"
                      component="CopiedIcon"
                    />
                  </span>
              </div>
            </div>
            <div className="d-flex justify-content-mobile mobile-column align-items-start wiggly">
              <div>
                <span className="fw-bold me-2">Repo Password:</span>
                <span className="repo-cred-hidden" ref={passwordRef} data-password="">..........</span>
              </div>
              <div className="d-flex gap-1 mobile-w100 justify-end-mobile pt-3-mobile">
                <div className="spinner-border spinner-border-sm text-secondary mx-2 d-none" ref={spinnerRef} role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                  <span
                    ref={revealRef}
                    className="mx-3 pr-5-mobile"
                    onClick={() => repoPasswordReveal(false)}
                  >
                    <ToolTipOverlay
                      keyId="password-reveal"
                      placement="top"
                      tooltipLabel="Reveal Password"
                      tooltipClass="tooltip-token"
                      component="RevealPassword"
                    />
                  </span>
                  <span
                    ref={hideRef}
                    className="d-none"
                    onClick={() => repoPasswordReveal(false)}
                  >
                    <ToolTipOverlay
                      keyId="password-hide"
                      placement="top"
                      tooltipLabel="Hide Password"
                      tooltipClass="tooltip-token"
                      component="HidePassword"
                    />
                  </span>
                <div className="d-flex gap-1">
                    <span
                      ref={copyPasswordRef}
                      className="mt-1"
                      onClick={() => repoPasswordReveal(true)}
                    >
                      <ToolTipOverlay
                        keyId="password-copy"
                        placement="top"
                        tooltipLabel="Copy Password"
                        tooltipClass="tooltip-token"
                        component="CopyIcon"
                      />
                    </span>
                    <span
                      ref={copiedPasswordRef}
                      className="d-none"
                    >
                    <ToolTipOverlay
                      keyId="password-copied"
                      placement="top"
                      tooltipLabel="Copied Password"
                      tooltipClass="tooltip-token"
                      component="CopiedIcon"
                    />
                    </span>
                </div>
              </div>
            </div>
          </div>
          <div className="token-flex align-items-end justify-content-end">
            <div className="py-3-mobile"><WhichRepoModal /></div>
            <AccessReposBtn version="1" requestAccess={props.requestAccess} />
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Repos1Card;