import { useLayoutEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { reposAccessApi } from "../../client/edbApiClient";
import CheckingForAccess from "../../components/dashboard/CheckingForAccess";
import Repos2Card from "../../components/dashboard/Repos2Card";
import Repos1Card from "../../components/dashboard/Repos1Card";
import TrialExpired from "../../components/dashboard/TrialExpired";
import AccountExpired from "../../components/dashboard/AccountExpired";
import { useSelector, useDispatch } from "react-redux";
import { reposAccessReceived } from "../../store/reposAccessSlice";
import { ReposAccess } from "../../resources/Types";
import SignedInAs from "../../components/dashboard/SignedInAs";
import { username } from "../../resources/OktaSessionData";
import { marketoPostData } from "../../client/edbApiClient";
import { email } from "../../resources/OktaSessionData";

interface reposAccessState {
  reposAccess: ReposAccess;
}

const Dashboard = () => {
  const [access, setAccess] = useState(false);
  const reposAccess = useSelector((state: reposAccessState) => state.reposAccess);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const campaignId = queryParams.get("campaignId");
    // Pass campaign id to marketo.
    sendMarketoData(campaignId);

    // Repo Access for user.
    fetchReposAccess()
  }, [dispatch])

  const fetchReposAccess = async () => {
    return await reposAccessApi()
    .then(res => {
      dispatch(reposAccessReceived(res));
      setAccess(true);
    })
  }

  const sendMarketoData = async (campaignId: string | null) => {
    if (campaignId === null) {
      campaignId = "";
    }

    // Pass campaign id to marketo.
    if (campaignId !== "") {
      await marketoPostData({
        "campaign_id": campaignId,
        "email": email
      });
    }
  }

  let edbEmployee = false;
  let trialExpired = false;
  let accountExpired = false;
  let showCloudsmith = false;
  //let cloudsmithMismatch = false;
  interface Legacy {
    repo?: {
      expiration: string | null;
      status: boolean;
    }
  }
  let legacy: Legacy | any;
  let showLegacy = false;
  //let accountExpired = false;
  let cloudsmithRequestAccess = true;
  let legacyRequestAccess = true;

  // Get reposAccess data.
  if (typeof reposAccess === 'object' && 'access' in reposAccess && Object.keys(reposAccess.access).length > 0) {
    edbEmployee = 'edbEmployee' in reposAccess.access ? reposAccess.access.edbEmployee : edbEmployee;
    trialExpired = 'trialExpired' in reposAccess.access ? reposAccess.access.trialExpired : trialExpired;
    showCloudsmith = 'showCloudsmith' in reposAccess.access ? reposAccess.access.showCloudsmith : showCloudsmith; 
    //cloudsmithMismatch = 'cloudsmithMismatch' in reposAccess.access ? reposAccess.access.cloudsmithMismatch : cloudsmithMismatch;
    legacy = reposAccess.access.legacy;
    if (Object.keys(legacy).length > 0 && 'repo' in legacy && 'status' in legacy.repo)  {
      showLegacy = legacy.repo.status;
      // If already has legacy then set request for it to false.
      legacyRequestAccess = false;
    }
    accountExpired = 'accountExpired' in reposAccess.access ? reposAccess.access.accountExpired : accountExpired;

    // If already has cloudsmith access then set request for it to false.
    cloudsmithRequestAccess = 'cloudsmith' in reposAccess.access && 
      Object.keys(reposAccess.access.cloudsmith).length > 0 ? false : cloudsmithRequestAccess

    if (edbEmployee) {
      showLegacy = true;
      showCloudsmith = true;
      cloudsmithRequestAccess = false;
    }
  }

  return (
    <Layout>
      <div className="hero hero-text position-relative">
        <div className="hero-pattern software-downloads-postgres position-absolute">
          <img alt="" src="/images/patterns/swirls-aqua.svg" className="swirl" />
        </div>
        <div className="hero-content">
          <div className="hero-content-wrapper">
          <div className="hero-content-inner-wrapper">
              <h1 className="hero-header d-flex align-items-start justify-content-between">
                <span className="header-text text-white">
                  EDB repositories
                </span>
                <SignedInAs />
              </h1>
              <p className="p-spacer"></p>
              {!access ? 
                (<CheckingForAccess />) 
                : 
                <>
                  {trialExpired && (
                    <TrialExpired />
                  )}
                  {accountExpired && (
                    <AccountExpired />
                  )}
                  {showCloudsmith && (
                    <Repos2Card 
                      username={username} 
                      requestAccess={cloudsmithRequestAccess} 
                      edbEmployee={edbEmployee} 
                    />
                  )}
                  {showLegacy && (
                    <Repos1Card 
                      username={username}
                      requestAccess={legacyRequestAccess} 
                    />
                  )}    
                </>}
          </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Dashboard;