import ProductList from "../pages/downloads/ProductList";
import ModifiedGPL from "../pages/downloads/ModifiedGPL";
import Dashboard from "../pages/repos/Dashboard";
import Repos2ListPage from "../pages/repos/Repos2ListPage";
import LegacyListPage from "../pages/repos/LegacyListPage";
import Logout from "../pages/logout"
import ThankYouPage from "../pages/ThankYouPage";
import AccountsLoginRedirect from "../pages/AccountsLoginRedirect";
import PlatformCompatibility from "../pages/PlatformCompatibility";
import PostgreSQLDownloadThankYouPage from "../pages/PostgreSQLDownloadThankYouPage";
import CrossOver1 from "../pages/CrossOver1";
import CrossOver2 from "../pages/CrossOver2";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { Security, LoginCallback, useOktaAuth } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from "../config";


export default function Router() {
  const okta = new OktaAuth(config.okta);

  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth:object, originalUri:string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), {replace: true});
  };

  //function to check if user is logged in else redirect to login page
  const RequiredAuth = () => {
    const { authState } = useOktaAuth();
    
    if(!authState) return <div>Loading...</div>

    if (authState.isAuthenticated){
        return (<Outlet />);
    } else {
      navigate(toRelativeUrl("/accounts/login", window.location.origin), {replace: true});
      //oktaAuth.signInWithRedirect({ originalUri: window.location.pathname })
      return null
    }
  }
// save for future reference
//<Route path="/postgresql-tutorial-resources-training-1" element={<PostgreSQLDownloadThankYouPage />} />
  return (
    <Security oktaAuth={okta} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
            <Route index element={ <ProductList /> } />
            <Route path="/login/callback" element={ <LoginCallback /> } />
            <Route path="/software-downloads-postgres" element={ <ProductList /> } />
            <Route path="/modified-gpl" element={ <ModifiedGPL /> } />
            <Route path="/resources/platform-compatibility" element={ <PlatformCompatibility /> } />
            <Route path="/logout" element={<Logout />} />
            <Route path="/accounts/login" element={<AccountsLoginRedirect />} />           
            <Route path="/postgresql-tutorial-resources-training-1" element={<CrossOver1 />} />
            <Route path="/postgresql-tutorial-resources-training-2" element={<CrossOver2 />} />
            <Route path="/" element={<RequiredAuth />}>
            <Route path="/repos-downloads" element={<Dashboard />} />
            <Route path="/repos" element={<Repos2ListPage />} />
            <Route path="/repos/legacy" element={<LegacyListPage />} />
            <Route path="/thank-you-page" element={<ThankYouPage />} />
            </Route>
        </Routes>
    </Security>
  );
}