import { useLayoutEffect } from "react";
import Layout from "../../components/layout/Layout";
import Hero from "../../components/layout/page/Hero";
import ReposListPageLayout from "../../components/layout/page/ReposListPageLayout";
import LegacyDirectReposDownloads from "../../components/repos/LegacyDirectReposDownloads";
import { reposAccessApi } from "../../client/edbApiClient";
import { useSelector, useDispatch } from "react-redux";
import { reposAccessReceived } from "../../store/reposAccessSlice";
import { ReposAccess } from "../../resources/Types";

interface reposAccessState {
  reposAccess: ReposAccess;
}

const LegacyListPage = () => {
  const reposAccess = useSelector((state: reposAccessState) => state.reposAccess);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    fetchReposAccess()
  }, [dispatch])

  const fetchReposAccess = async () => {
    return await reposAccessApi()
    .then(res => {
      dispatch(reposAccessReceived(res));
    })
  }

  let showCloudsmith = false;
  // Get reposAccess data.
  if (typeof reposAccess === 'object' && 'access' in reposAccess && Object.keys(reposAccess.access).length > 0) {
    showCloudsmith = 'showCloudsmith' in reposAccess.access ? reposAccess.access.showCloudsmith : showCloudsmith; 
  }

  let subLink = {
    title: "",
    url: ""
  }
  // Only show link to Repos 2.0 if user has access.
  if (showCloudsmith) {
    subLink = {
      title: "Switch to Repos",
      url: "repos"
    }
  }

  return (
    <Layout>
      <Hero title="EDB repositories for Linux" class="repos-legacy" sublink={subLink} />
      <ReposListPageLayout page="legacy" repoType="legacy" />
      <LegacyDirectReposDownloads />
    </Layout>
  )
}

export default LegacyListPage;