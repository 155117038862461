import React, { useRef } from "react";
import WhichRepoModal from "./WhichRepoModal";
import AccessReposBtn from "./AccessReposBtn";
import {
  cloudsmithTokenApi,
  edbEmployeecloudsmithTokenApi,
} from "../../client/edbApiClient";
import { Cloudsmith } from "../../resources/Types";
import ToolTipOverlay from "../ToolTipOverlay";
import "../../css/repo-downloads.css";

interface Repos2Props {
  username: string;
  requestAccess: boolean;
  edbEmployee: boolean;
}

const Repos2Card = (props: Repos2Props) => {
  const tokenRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const spinnerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const revealRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const hideRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copyRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  const copiedRef = useRef() as React.MutableRefObject<HTMLSpanElement>;

  const tokenReveal = (copy: boolean) => {
    // Show spinner.
    spinnerRef.current.className = spinnerRef.current.className.replace(
      "d-none",
      ""
    );

    // Get token data attribute.
    const tokenDataAttr = tokenRef.current.getAttribute("data-token");
    // Type Guard the tokenDataAttr to pass to fetchCloudsmithToken() function
    // to prevent null error on passing argument to the function.
    const tokenDataAttrString: string =
      tokenDataAttr !== null ? tokenDataAttr : "";
    if (tokenRef.current.className === "repo-cred-hidden") {
      // Get Token.
      fetchCloudsmithToken(tokenDataAttrString, props.edbEmployee, copy);
    } else {
      if (copy) {
        // Hide spinner.
        spinnerRef.current.className = spinnerRef.current.className + " d-none";
        // Copy token to clipboard.
        navigator.clipboard.writeText(tokenDataAttrString);
        // Change Copy label to Copied.
        copyRef.current.className = "d-none";
        copiedRef.current.className = "show mt-1";
        setTimeout(() => {
          copyRef.current.className = "show mt-1";
          copiedRef.current.className = "d-none";
        }, 3000);
      } else {
        // Change className back to hidden.
        tokenRef.current.className = "repo-cred-hidden";
        // Hide token.
        tokenRef.current.innerHTML = "........................";
        // Hide spinner.
        spinnerRef.current.className = spinnerRef.current.className + " d-none";
        // Change hide password icon to show password icon.
        revealRef.current.className = "show mx-3";
        hideRef.current.className = "d-none";
      }
    }
  };

  const fetchCloudsmithToken = async (
    dataAttr: string,
    edb: boolean,
    copy: boolean
  ) => {
    if (dataAttr === "") {
      let getToken = () => {
        return cloudsmithTokenApi();
      };

      if (edb) {
        getToken = () => {
          return edbEmployeecloudsmithTokenApi();
        };
      }

      // Getting token from api.
      return await getToken().then((res: Cloudsmith[] | unknown) => {
        if (res !== null && typeof res === "object") {
          const cloudsmith: Cloudsmith = res;
          let token = "";
          if (
            "token" in cloudsmith &&
            typeof cloudsmith.token !== "undefined"
          ) {
            token = cloudsmith.token;
          }
          if (copy) {
            // Copy token to clipboard.
            navigator.clipboard.writeText(token);
            // Change Copy label to Copied.
            copyRef.current.className = "d-none";
            copiedRef.current.className = "show mt-1";
            setTimeout(() => {
              copyRef.current.className = "show mt-1";
              copiedRef.current.className = "d-none";
            }, 3000);
          } else {
            // Change class name.
            tokenRef.current.className = "repo-cred-shown mt-2";
            // Show Token.
            tokenRef.current.innerHTML = token;
            // Change show password icon to hide password icon.
            revealRef.current.className = "d-none";
            hideRef.current.className = "show mx-3";
          }
          // Set token data attribute to reveal by data attribute
          // on subsequent clicks to avoid more api calls.
          // only if token was not just created with shows asteriks.
          if (token !== "********************************") {
            tokenRef.current.setAttribute("data-token", token);
          }
          // If token is showing asterisks which means createToken was called
          // during getToken because trial user is getting acess to more repos
          // then refresh the page to allow user to see actual token.
          if (token === "********************************") {
            window.location.reload();
          }
          // Hide spinner.
          spinnerRef.current.className =
            spinnerRef.current.className + " d-none";
        }
      });
    } else {
      // Getting token from data attribute.
      if (copy) {
        // Copy token to clipboard.
        navigator.clipboard.writeText(dataAttr);
        // Change Copy label to Copied.
        copyRef.current.className = "d-none";
        copiedRef.current.className = "show mt-1";
        setTimeout(() => {
          copyRef.current.className = "show mt-1";
          copiedRef.current.className = "d-none";
        }, 3000);
      } else {
        // Change class name.
        tokenRef.current.className = "repo-cred-shown";
        // Show Token.
        tokenRef.current.innerHTML = dataAttr;
        // Change show password icon to hide password icon.
        revealRef.current.className = "d-none";
        hideRef.current.className = "show mx-3";
      }
      // Hide spinner.
      spinnerRef.current.className = spinnerRef.current.className + " d-none";
    }
  };

  return (
    <div id="repo2-card" className="card p-5 mb-5">
      <div className="d-none">{props.username}</div>
      <h2 className="h4">EDB Repos 2.0</h2>
      <div className="align-items-end justify-content-between mobile-container container">
        <div className="card-row mobile-w100">
          <div className="card-col-6 hide-mobile">
            <p>Access to EDB's improved download experience</p>
            <ul className="card-list">
              <li>
                Currently available for
                <span className="fw-bold ms-1">
                  EDB Enterprise, EDB Standard, Trusted Postgres Architect, EDB
                  Postgres Distributed 5, EDB Postgres for Kubernetes, and EDB
                  Postgres Distributed for Kubernetes.
                </span>
              </li>
              <li>Token-based authentication</li>
              <li>Single, simple URL for all download types</li>
              <li>
                Currently supporting
                <span className="fw-bold ms-1">
                  Red Hat Enterprise Linux (RHEL), Oracle Linux (OL), CentOS,
                  Rocky Linux, AlmaLinux, Debian, Ubuntu, and SUSE Linux
                  Enterprise Server (SLES)
                </span>
              </li>
            </ul>
          </div>
          <div className="card-col-6">
            <div className="d-flex align-items-center justify-content-mobile mobile-column repo-creds repo-two h-25">
              <p className=" mobile-column">
                <span className="fw-bold me-2">Repo Token:</span>
                <span
                  className={props.requestAccess ? "" : `repo-cred-hidden`}
                  ref={tokenRef}
                  data-token=""
                >
                  {props.requestAccess
                    ? "Has not been generated."
                    : "........................"}
                </span>
              </p>
              {!props.requestAccess && (
                <div className="d-flex pl-5 mobile-w100 justify-end-mobile pt-3-mobile">
                  <div
                    className="spinner-border spinner-border-sm text-secondary mx-2 d-none"
                    ref={spinnerRef}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <div className="token-reveal-wrapper d-flex">
                    <span
                      ref={revealRef}
                      className="mx-3 pr-5-mobile"
                      onClick={() => tokenReveal(false)}
                    >
                      <ToolTipOverlay
                        keyId="token-reveal"
                        placement="top"
                        tooltipLabel="Reveal Token"
                        tooltipClass="tooltip-token"
                        component="RevealPassword"
                      />
                    </span>
                    <span
                      ref={hideRef}
                      className="d-none"
                      onClick={() => tokenReveal(false)}
                    >
                      <ToolTipOverlay
                        keyId="token-hide"
                        placement="top"
                        tooltipLabel="Hide Token"
                        tooltipClass="tooltip-token"
                        component="HidePassword"
                      />
                    </span>

                    <div className="d-flex">
                      <span
                        ref={copyRef}
                        className="mt-1"
                        onClick={() => tokenReveal(true)}
                      >
                        <ToolTipOverlay
                          keyId="token-copy"
                          placement="top"
                          tooltipLabel="Copy Token"
                          tooltipClass="tooltip-token"
                          component="CopyIcon"
                        />
                      </span>
                      <span ref={copiedRef} className="d-none">
                        <ToolTipOverlay
                          keyId="token-copied"
                          placement="top"
                          tooltipLabel="Token Copied"
                          tooltipClass="tooltip-token"
                          component="CopiedIcon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="token-flex justify-content-end">
              <div className="py-3-mobile">
                <WhichRepoModal />
              </div>
              <AccessReposBtn version="2" requestAccess={props.requestAccess} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Repos2Card;
